import React from "react";

import models from '../../assets/icons/models.svg'
import categories from '../../assets/icons/categories.svg'
import whatsapp from '../../assets/icons/whatsapp.svg'
import whatsappGray from '../../assets/icons/ws.svg'
import rent from '../../assets/icons/rent.svg'
import call from '../../assets/icons/call.svg'
import {clientWindow, getResponsiveType, IResponsiveType} from "../../utils/uiUtils";
import { getSiteData } from "../../data/data";

let positionReference = 0;
let scrollPositionReference = 0;

const VehiclesCatalog = (
    props:
        {
            handleResponsiveOpen: (nexStatus: boolean) => void,
            openWA: (vehicle?: string) => void
            changeVehicleOpen: (vehicleOpen: any) => void
        }
        ) => {
    const cityData = getSiteData()
    const siteData = getSiteData();

    const [responsive, changeResponsive] = React.useState<IResponsiveType>(getResponsiveType())
    const [scrollPosition, handleScrollPosition] = React.useState(0);
    const [offsetTopFilter, handleOffsetTopFilter] = React.useState(0);
    const [activeCategory, handleActiveCategory] = React.useState<string>('');
    const [isOpenCategories, handleIsOpenCategories] = React.useState<boolean>(false);
    const scrollContent: any = React.useRef(null);
    const vehiclesContent: any = React.useRef(null);
    const vehiclesContentTop: any = React.useRef(null);

    const handleResizing = () => {
        changeResponsive(getResponsiveType())
        const scrollHeight = document.getElementById('homeBanner')?.clientHeight || 0;
        if (scrollPositionReference !== scrollHeight) {
            scrollPositionReference = scrollHeight
            handleOffsetTopFilter(scrollHeight);
        }
    };

    const checkScrollPosition = () => {
        const scrollContentY = clientWindow.pageYOffset;
        if (positionReference !== scrollContentY) {
            positionReference = scrollContentY;
            handleScrollPosition(scrollContentY)
        }
        if (!scrollPositionReference) {
            const scrollHeight = document.getElementById('homeBanner')?.clientHeight || 0;
            scrollPositionReference = scrollHeight
            handleOffsetTopFilter(scrollHeight);
        }
    };

    const activeCat = activeCategory ? cityData.vehicles.find(ct => ct.name === activeCategory) : null

    const onFilter = (catName: string) => {
        handleIsOpenCategories(false);
        handleActiveCategory(catName);
        vehiclesContentTop.current.scrollIntoView({block: "start", behavior: "smooth"})
    }

    const resetButton = <a className="flex-row text-primary flex-middle" onClick={() => onFilter('')}>
        <span className="flex-column p-r">Ver todos los autos en alquiler</span>
        <span className="flex-column"><img src={models} alt=""/></span>
    </a>


    React.useEffect(() => {
        clientWindow.addEventListener('resize', handleResizing);
        return () => {
            clientWindow.removeEventListener('resize', handleResizing);
        };
    }, []);

    React.useEffect((): any => {
        if (responsive !== 'large') {
            return null;
        }
        const scrollInterval = setInterval(checkScrollPosition, 250);
        return () => clearInterval(scrollInterval);
    }, [responsive]);

    return <>
        <div className="vehicles-catalog" ref={scrollContent}>
            <div className={
                `vehicles-catalog-filter bg-gray bg-lighten-6 p-l p-r flex-row flex-middle ${isOpenCategories && 'open-selector'} ${scrollPosition > offsetTopFilter - 70 ? 'active-fixed' : 'inactive-fixed'}`
            }>
                <div className="flex-column flex-1">
                    <nav>
                        <div className="model-selector flex-row flex-middle">
                            <div className="flex-column cursor-pointer flex-1" onClick={() => handleIsOpenCategories(true)}>
                                <h3>Renta el auto que se adapta a ti</h3>
                                <p>{activeCat?.name || 'Selecciona el tipo de Vehículo'}</p>
                            </div>
                            <div className="flex-column cursor-pointer" onClick={() => handleIsOpenCategories(true)}>
                                <img src={categories} alt=""/>
                            </div>
                            <div className={`vehicle-list ${isOpenCategories && 'open-list'}`}>
                                <ul className="list-flat">
                                    {cityData.vehicles.map((cat, catKey) => {
                                        const isActive = activeCategory === cat.name
                                        return <li key={catKey}>
                                            <a
                                                onClick={() => {
                                                    onFilter(cat.name)
                                                }}
                                                className={`flex-row flex-middle ${isActive && 'active-category'}`}
                                            >
                                                <span className="p-l flex-column flex-middle flex-center"><img className="icon-32x" src={cat.icon} alt=""/></span>
                                                <span className="p-l text-gray text-lighten-3">{cat.name}</span>
                                            </a>
                                        </li>
                                    })}
                                    <li className="flex-center no-nav flex-row m-t">
                                        {resetButton}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <a className="call-button" href={`tel:+593${siteData.phone}`}><img src={call} alt="" className="icon-24x"/></a>
                    </nav>
                    <div className="responsive-cta bg-gray radius-default flex-row m-t-s">
                        <a
                            className="p-s-all flex-column flex-1 border-r border-gray border-lighten-3 flex-middle"
                            onClick={() => props.handleResponsiveOpen(true)}
                        >
                            <span className="flex-row flex-middle">
                                <span className="flex-column">
                                    <img className="icon-24x" src={rent} alt=""/>
                                </span>
                                <span className="flex-column flex-1 text-white p-l-s">
                                    <small>Alquilar el vehículo</small>
                                    <strong className="size-5 line-height-small">Desde la web</strong>
                                </span>
                            </span>
                        </a>
                        <a className="p-s-all flex-column flex-1 flex-middle" onClick={() => props.openWA()}>
                            <span className="flex-row flex-middle">
                                <span className="flex-column">
                                    <img className="icon-24x" src={whatsappGray} alt=""/>
                                </span>
                                <span className="flex-column flex-1 text-white p-l-s">
                                    <small>Rentar por WhatsApp</small>
                                    <strong className="size-5 line-height-small">0{siteData.phone}</strong>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>
                <div className="all-vehicles">
                    {resetButton}
                </div>
            </div>
            <div className="container container-flex model-list" ref={vehiclesContent}>
                <div ref={vehiclesContentTop} className="top-list"/>
                {(activeCat ? [activeCat] : cityData.vehicles).map((cat, index) => {
                    return <div className="row" key={index}>
                        <div className="col-s12">
                            <h2 className="light main-title-cat flex-row flex-middle line-height-small p-t p-b flex-no-wrap">
                                <span className="flex-column p-r">
                                    <img src={cat.icon} className="icon-32x" alt=""/>
                                </span>
                                <span className="flex-column">
                                    <span className="flex-row">{cat.name} en alquiler </span>
                                    <span className="flex-row size-4 text-gray text-lighten-2">
                                        Vehículos disponibles
                                    </span>
                                </span>
                            </h2>
                        </div>
                        {cat.vehicles.map((vh, key) => {
                            return <div className="col-s12 col-m6 col-l6 col-xl4 col-big3 m-b-lg m-t vehicle-item" key={key}>
                                <div className="flex-column width-100">
                                    <div className="flex-row">
                                        <div className="flex-column image-area">
                                            <img src={vh.image} alt={`renta y alquiler de ${vh.brand} ${vh.model} en ${cityData.city}`} />
                                        </div>
                                        <div className="flex-column p-l flex-1">
                                            <h3 className="p-t p-b border-b border-gray border-lighten-5 line-height-small">
                                                <span className="text-gray text-lighten-3">{vh.brand}</span> {vh.model}
                                            </h3>
                                            <div className="flex-row p-t p-b flex-no-wrap">
                                                <div className="flex-column text-gray text-lighten-4 line-height-small p-t-s">
                                                    <small className="text-uppercase">desde</small>
                                                    <span className="size-3 text-gray text-lighten-1 strong line-height-small">${vh.price}</span>
                                                    <small>diarios</small>
                                                </div>
                                                <ul className="flex-column m-t-none feature-list">
                                                    {vh.features.map((ft, sk) => {
                                                        return <li key={sk}>
                                                            {ft}
                                                        </li>
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-row border-t border-gray border-lighten-5 bg-gray bg-lighten-6 p-t-s p-b-s vehicle-ctas">
                                        <div className="flex-column flex-middle">
                                            <a className="strong rent-cta no-wrap" onClick={() => {
                                                props.changeVehicleOpen(vh)
                                            }}>
                                                Rentar vehículo <span className="size-small"><i className="m-l-s icon-arrow-right" /></span>
                                            </a>
                                        </div>
                                        <div className="flex-column flex-middle flex-center">
                                            <a className="strong flex-row flex-middle flex-center" onClick={() => props.openWA(`${vh.brand} ${vh.model}`)}>
                                                <span className="flex-column">Rentar por Whatsapp</span><span className="flex-column p-l-s"><img src={whatsapp} alt=""/></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                })}
            </div>
        </div>
    </>
}

export default VehiclesCatalog
