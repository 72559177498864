import React from "react";
import ContactFormComponent from "../global/ContactFormComponent";
import { getSiteData } from "../../data/data";

interface IContactProps {
    contactOpen: boolean
    changeContactOpen: (isOpen: boolean) => void
}

const ModalContactComponent = (props: IContactProps) => {
    const siteData = getSiteData();

    const close = () => {
        props.changeContactOpen(false)
    }

    return <div className="contact-modal">
        {props.contactOpen &&
            <div className="modal-content">
                <div className="close-bg" onClick={close} />
                <div className="bg-white form-area radius-big flex-row">
                    <div className={`block-area flex-column flex-center relative ${siteData.map ? 'flex-075' : 'flex-010'}`}>
                        <a className="close-contact size-2" onClick={close}><i className="icon-close" /></a>
                        {siteData.map &&
                            <iframe
                                frameBorder="0"
                                scrolling="no"
                                src={siteData.map}
                                aria-label="Wm Rentacar"
                                style={{ width: '100%', height: '100%' }}
                            />
                        }
                    </div>
                    <div className="block-area flex-column bg-gray p-all flex-1 form-content">
                        <p className="strong text-white line-height-small m-t m-b">
                            Wm Rent a Car - Alquiler Autos {siteData.city} Ecuador - Ecuador
                        </p>
                        {siteData.address &&
                            <p className="text-white p-b line-height-small">
                                {siteData.address}
                            </p>
                        }
                        <ContactFormComponent />
                    </div>
                </div>
            </div>
        }
    </div>
}

export default ModalContactComponent;
