import Sedan from '../assets/icons/sedan.svg';
import Truck from '../assets/icons/camionetas.svg';
import SUV from '../assets/icons/suv.svg';
import Premium from '../assets/icons/gama_alta.svg';
import Van from '../assets/icons/van.svg';
import Automatic from '../assets/icons/automatic.svg';
import { IVehicleCategory } from './data';

const VEHICLES: IVehicleCategory[] = [
    {
        name: 'Sedanes',
        icon: Sedan,
        vehicles: [
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_beat.jpg'),
                model: 'Beats',
                price: 60,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth'
                ]
            },
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_sail.png'),
                model: 'Sail',
                price: 65,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth'
                ]
            },
            {
                brand: 'Kia',
                image: require('../assets/vehicles/kia_rio_automatico.jpg'),
                model: 'Río',
                price: 75,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción automática'
                ]
            },
        ]
    },
    {
        name: 'Camionetas',
        icon: Truck,
        vehicles: [
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_dmax.png'),
                model: 'Dmax',
                price: 105,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x4',
                ]
            },
            {
                brand: 'Dongfeng',
                image: require('../assets/vehicles/donfeng_rich_6.jpg'),
                model: 'Rich 6',
                price: 105,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x4',
                ]
            },
        ]
    },
    {
        name: 'SUV',
        icon: SUV,
        vehicles: [
            {
                brand: 'Kia',
                image: require('../assets/vehicles/kia_sportage.png'),
                model: 'Sportage',
                price: 110,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x2'
                ]
            },
            {
                brand: 'GAC',
                image: require('../assets/vehicles/gac_gs4_automatico.jpg'),
                model: 'GS4 All New',
                price: 110,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción automática'
                ]
            },
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_sz.png'),
                model: 'SZ',
                price: 90,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x2'
                ]
            }
        ]
    },
    {
        name: 'Gama Alta',
        icon: Premium,
        vehicles: [
            {
                brand: 'Toyota',
                image: require('../assets/vehicles/toyota_new_fortuner.jpg'),
                model: 'New Fortuner',
                price: 170,
                features: [
                    'Aire Acondicionado',
                    '7 Asientos',
                    'Bluetooth',
                    'Tracción 4x4'
                ]
            }
        ]
    },
    {
        name: 'Van',
        icon: Van,
        vehicles: [
            {
                brand: 'Hyundai',
                image: require('../assets/vehicles/hyundai_h1.png'),
                model: 'H1',
                price: 130,
                features: [
                    'Aire Acondicionado',
                    '12 Asientos',
                    'Bluetooth'
                ]
            },
            {
                brand: 'Karry',
                image: require('../assets/vehicles/karry_mini_van.jpg'),
                model: 'Mini Van',
                price: 110,
                features: [
                    'Aire Acondicionado',
                    '10 Asientos',
                    'Bluetooth'
                ]
            },
        ]
    },
]

export default VEHICLES;