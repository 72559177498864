import rentACarQuito from '../assets/icons/wm_rent_a_car_alquile_de_autos.svg'
import rentACarManta from '../assets/icons/wm_rent_a_car_alquile_de_autos_manta.svg'

export interface IVehicle {
    image: any;
    model: string;
    brand: string;
    features: string[];
    price: number
}

export interface IVehicleCategory {
    name: string;
    icon: any;
    vehicles: IVehicle[];
}

export const getSiteData = (): { city: string, vehicles: IVehicleCategory[], brand: any, phone: string, address?: string, map?: string } => {
    const city = process.env.REACT_APP_CITY || 'QUITO';
    switch (city) {
        case 'MANTA':
            const vehiclesM: IVehicleCategory[] = require('./manta').default;
            return {
                brand: rentACarManta,
                city: 'Manta',
                vehicles: vehiclesM,
                phone: '997717421'
            }
        case 'QUITO':
        default:
            const vehiclesQ = require('./quito').default;
            return {
                brand: rentACarQuito,
                city: 'Quito',
                vehicles: vehiclesQ,
                phone: '969779012',
                map: "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d249.36247402278136!2d-78.4768911548914!3d-0.17558985956724546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2sec!4v1646247901940!5m2!1ses-419!2sec",
                address: "Edificio Genesis - Gonzalo Serrano E10-62, y 6 de Diciembre, Quito - Ecuador"
            }
    }
}